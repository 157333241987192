const model = {
  first_name: '',
  last_name: '',
  username: '',
  email: '',
  password: '',
  confirm_password: '',
  company: '',
  value_chain: ''
};

const form = [
  {
    type: 'text',
    name: 'first_name',
    label: 'Firstname',
    placeholder: 'Your firstname',
    component: 'el-input',
    required: true,
  },
  {
    type: 'text',
    name: 'last_name',
    label: 'Lastname',
    placeholder: 'Your lastname',
    component: 'el-input',
    required: true,
  },
  {
    type: 'text',
    name: 'username',
    label: "Display name",
    placeholder: "Your display name",
    component: 'el-input',
    required: true,
  },
  {
    type: 'text',
    name: 'email',
    label: 'Email',
    placeholder: 'Your email address',
    component: 'el-input',
    required: true,
  },
  {
    type: 'password',
    name: 'password',
    label: 'Password',
    placeholder: 'Your password',
    component: 'el-input',
    required: true,
  },
  {
    type: 'password',
    name: 'confirm_password',
    label: 'Confirm password',
    placeholder: 'Confirm your password',
    component: 'el-input',
    required: true,
  },
  {
    type: 'text',
    name: 'company',
    label: 'Company',
    placeholder: 'Your company name',
    required: false,
    component: 'el-input',
  },
    {
    type: 'select',
    name: 'value_chain',
    label: 'Value chain',
    placeholder: 'Select your value chain',
    component: 'el-select',
    required: true,
    options: [
      {
        label: 'Extraction des matières',
        value: 'Extraction des matières',
      },
      {
        label: 'Fabrication des composants de cellules',
        value: 'Fabrication des composants de cellules',
      },
      {
        label: 'R&D dans la conception des batteries',
        value: 'R&D dans la conception des batteries',
      },
      {
        label: 'Fabrication des blocs de batteries',
        value: 'Fabrication des blocs de batteries',
      },
      {
        label: 'Fabrication de véhicules électriques',
        value: 'Fabrication de véhicules électriques',
      },
      {
        label: 'Fabrication de véhicules de petite mobilité',
        value: 'Fabrication de véhicules de petite mobilité',
      },
      {
        label: 'Maintenance et réparation des véhicules',
        value: 'Maintenance et réparation des véhicules',
      },
      {
        label: 'Collecte des batteries',
        value: 'Collecte des batteries',
      },
      {
        label: 'Elimination des batteries',
        value: 'Elimination des batteries',
      },
      {
        label: 'Recyclage des batteries',
        value: 'Recyclage des batteries',
      },
      {
        label: 'R&D dans le recyclage des batteries',
        value: 'R&D dans le recyclage des batteries',
      }
    ]
  }
];

const rules = {
  first_name: [
    {
      type: 'required',
      message: 'Please enter your firstname',
    },
  ],
  last_name: [
    {
      type: 'required',
      message: 'Please enter your lastname',
    },
  ],
  username: [
    {
      type: 'required',
      message: "Please enter your display name",
    },
  ],
  email: [
    {
      type: 'required',
      message: 'Please enter your email address',
    },
    {
      type: 'email',
      message: 'Please enter a valid email address',
    },
  ],
  password: [
    {
      type: 'required',
      message: 'Please enter your password',
    },
    {
      type: 'minLength',
      minLength: 8,
    },
  ],
  confirm_password: [
    {
      type: 'required',
      message: 'Please confirm your password',
    },
    {
      type: 'confirm',
      confirmField: 'password',
      message: 'Passwords do not match',
    },
  ],
  company: [],
  value_chain: []
};

export { model, form, rules };
